import { GetGlobalWorkspaceDocument } from './use-global-workspace.generated'

export default function () {
  const { data: globalWorkspace } = useQuery({
    query: GetGlobalWorkspaceDocument,
    tags: ['workspace-global'],
  }, ({ data }) => data?.workspace)

  return { globalWorkspace }
}
